import { GetObjectResponse } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const dataKey = 'data';
const getObjectByIdKey = 'getObjectById';

const gooseState = (state: RootState) => state.goose;

// Must only be used when there is a guarantee that the data exist in RTK cache and is not gonna be deleted
export const getGooseObjectById = (objectId: string) =>
    createSelector(gooseState, (stateRoot) => {
        const gooseObject: GetObjectResponse | undefined = (
            Object.entries(stateRoot.queries).find(([queryId, queryValue]: [string, any]) => {
                const isValidQueryId = queryId.includes(getObjectByIdKey);
                const isValidQueryValue = queryValue[dataKey]?.object?.uuid === objectId;
                return isValidQueryId && isValidQueryValue;
            })?.[1] as any
        )?.[dataKey];

        return gooseObject;
    });
