import { useLazyGetObjectByIdQuery } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/store/store';
import { Schemas } from 'src/visualization/constants';
import { DownholeCollectionType, GeologicalModelMeshesType } from 'src/visualization/types';
import {
    processDownholeCollection,
    processGeologicalModelMesh,
} from 'src/visualization/utils/generateProjectTree';

export function useQueue(maxConcurrent: number) {
    const [getObjectByIdTrigger] = useLazyGetObjectByIdQuery();
    const dispatch = useAppDispatch();
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);
    const loadObject = (objectId: string) =>
        getObjectByIdTrigger({
            objectId,
            orgId,
            workspaceId,
        }).unwrap();

    const queueRef = useRef<string[]>([]);
    const pendingRef = useRef<number>(0);
    const [timestamp, setTimestamp] = useState<number>(Date.now());

    function enqueue(objectToLoad: string) {
        queueRef.current.push(objectToLoad);
        setTimestamp(Date.now());
    }

    async function dequeue() {
        if (pendingRef.current < maxConcurrent && queueRef.current.length) {
            const objectId = queueRef.current.shift();
            if (objectId) {
                pendingRef.current += 1;
                setTimestamp(Date.now());
                try {
                    const response = await loadObject(objectId);
                    if (pendingRef.current > 0) {
                        pendingRef.current -= 1;
                        setTimestamp(Date.now());
                    }
                    const isDownholeCollections = response.object?.schema.includes(
                        Schemas.DownholeCollectionSchema,
                    );
                    const isGeologicalModelMeshes = response.object?.schema.includes(
                        Schemas.GeologicalModelMeshesSchema,
                    );

                    if (isDownholeCollections) {
                        processDownholeCollection(
                            response.object as DownholeCollectionType,
                            dispatch,
                        );
                    }
                    if (isGeologicalModelMeshes) {
                        processGeologicalModelMesh(
                            response.object as GeologicalModelMeshesType,
                            dispatch,
                        );
                    }
                } catch (error) {
                    trackError(`Error loading object ${objectId}:`, JSON.stringify(error));
                }
            }
        }
    }

    function clearQueue() {
        queueRef.current = [];
        pendingRef.current = 0;
    }

    useEffect(() => {
        if (!queueRef.current.length) {
            return;
        }

        const batchSize = maxConcurrent - pendingRef.current;
        for (let i = 0; i < batchSize; i += 1) {
            dequeue();
        }
    }, [timestamp]);
    useEffect(
        () => () => {
            clearQueue();
        },
        [],
    );

    return { enqueue };
}
